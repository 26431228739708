import React, {useEffect, useState} from 'react';
import ScrollToTop from "../../utils/scrollToTop";

import authenticationStore from "../../stores/AuthenticationStore.ts";
import MiniDrawer from "../../components/Navbar/Drawer";

import {desktopCgpLinks, generalCgpLinks, soonCgpLinks, mobileCgpLinks} from "./Links/cgpLinks";
import MobileNav from "../../components/Navbar/Mobile";

import IntegratedSignature from "../../components/IntegratedSignature";

import './style.scss';

const CgpRoutes = ({children}) => {
  const authToken = localStorage.getItem('CgpUserAuthToken');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const currentPath = window.location.href;
  const [signatureModal, setSignatureModal] = useState(false);
  //const width = window.innerWidth;
  //const isMobile = width <= 900;

  async function fetchUser() {
    await authenticationStore.loadCgpUserData().then(response => {
      setUser(response);
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
		authToken && localStorage.removeItem('UserAuthToken');
    if (!authToken || authToken === 'undefined') {
      return window.location.replace('/sign-in/cgp');
    } else if (!loading && (user.has_uploaded && user.kyc_verification_status !== "verified")) {
      return window.location.replace('/cgp/verification');
    } else if (!loading && (!user.has_uploaded && user.kyc_verification_status !== "verified")) {
      return window.location.replace('/sign-up/cgp/documents');
    }
    if (!currentPath.includes('/finalise')) {
      localStorage.removeItem('InvestmentAmount');
      localStorage.removeItem('CgpEntryFees');
      localStorage.removeItem('FundsCall');
      localStorage.removeItem('InvestmentType');
      localStorage.removeItem('product');
      localStorage.removeItem('productUrl');
    }
  }, [authToken, user, currentPath]);

  useEffect(() => {
    if (user?.kyc_verification_status === 'verified' && user?.master_ct.status !== 'signed') {
      setSignatureModal(true);
    }
  }, [user]);

  const updateShow = (bool) => {
    setSignatureModal(bool)
  }

  return (
    <>
      <ScrollToTop />
      {/*<Navbar role="cgp" />*/}
      <div className="layout-container">
        <div className="drawer">
          <MiniDrawer links={desktopCgpLinks} generalLinks={generalCgpLinks} soonLinks={soonCgpLinks} cgp user={user}/>
        </div>
        <div className="page-container">
					{React.cloneElement(children, { cgpUser: user })}
          <div className="mobile-nav">
            <MobileNav links={mobileCgpLinks}/>
          </div>
        </div>
      </div>
      {signatureModal && (
        <IntegratedSignature show={signatureModal} updateShow={updateShow}
                             signatureLink={user?.master_ct.file}
                             body="Ici vous pourrez consulter et signer le contrat master Openstone, si celui-ci n'apparait pas veuillez nous contacter: partenaires@openstone.com"
                             title="Signature du contrat master - Openstone"
        />
      )}
    </>
  )
};

export default CgpRoutes;
