import React from 'react';
import OpportunityCardInfos from "../../View/components/opportunityCardInfos";
import NumberHelper from "../../../../Helpers/NumberHelper";
import Divider from "@mui/material/Divider";
import getBadge from "../../../../components/Badge/getBadge";
import MoneyHelper from "../../../../Helpers/MoneyHelper";

import './style.scss';
const ProductCardRecap = ({product, selectedPromotion, minimumInvestment, object}) => {
  const withdrawalPage = window.location.href.includes("withdraw");
  const renderKeyPoints = () => (
    <>
      {product?.actual_rate && <OpportunityCardInfos label="TRI net cible" content="Le taux de rentabilité net annuel estimé, fourni à titre indicatif" value={product?.actual_rate} />}
      {product?.horizon && <OpportunityCardInfos label="Horizon" content="Horizon d'investissement, fourni à titre indicatif" value={product?.horizon}/>}
      {minimumInvestment && <OpportunityCardInfos label="Investissement minimum" value={`${NumberHelper.numberWithSpaces(minimumInvestment)} €`}/>}
      {/*selectedPromotion !== null &&
        <div className="promo-info">
          <p className="os-body2">Code promo <strong>{selectedPromotion?.coupon?.value}</strong> actif pour cette transaction</p>
          <p className="os-body2">{selectedPromotion?.coupon?.info_text}</p>
        </div>
      */}
    </>
  );

  return (
    <div className="information-recap-content">
      <h6 className="os-h6" style={{marginBottom: 8}}>{product.name}</h6>
      <img className="information-recap-content-img" src={product?.cover_picture?.large} alt=""/>
      {/*!withdrawalPage && getBadge('verified', 'kyc_status', `Solde actuel du client: ${MoneyHelper.formatEuro(object?.currentBalance)}`, 'info')*/}
      <Divider style={{marginBottom: 16, marginTop: 16}} />
      {renderKeyPoints()}
    </div>
  )
};

export default ProductCardRecap;
