import React, {useEffect, useState} from 'react';
import {Step, StepLabel, Stepper} from "@mui/material";
import {useLocation, useParams} from "react-router";
import {useNavigate, useSearchParams} from "react-router-dom";
import Divider from "@mui/material/Divider";

import AuthenticationNetwork from "../../../config/Network/AuthenticationNetwork.ts";
import CompanyNetwork from "../../../config/Network/CompanyNetwork.ts";
import ProductNework from "../../../config/Network/ProductNework.ts";
import CgpNetwork from "../../../config/Network/CgpNetwork.ts";

import Paiement from "./Steps/Paiement";
import Signature from "./Steps/Signature";
import StepsContainer from "./Steps/StepsContainer";
import FinaliseLoader0 from "../../../components/Loaders/Opportunities/Finalise/Step 0";
import FinaliseLoaderMobile from "../../../components/Loaders/Opportunities/Finalise/Step 0/Mobile";
import {ReactComponent as NoData} from "../../../assets/Illustrations/faq.svg";
import EmptyContent from "../../../components/empty-content/EmptyContent";
import SentSuccess from "./Steps/Signature/sentSuccess";
import IntegratedSignature from "../../../components/IntegratedSignature";
import CgpPaiementChoice from "./Steps/Paiement/CGP/paiementChoice";
import {toast} from "react-toastify";
import MoneyHelper from "../../../Helpers/MoneyHelper";
import DepositForm from "../../../Forms/Opportunities/Deposit/DepositForm";

import './Steps/stepsStyle.scss';
import './style.scss';

const finalisationSteps = [
  {  id: 0, title: "Modalités d'investissement" },
  {  id: 1, title: "Renseignement" },
  {  id: 2, title: "Signature des documents" },
  {  id: 3, title: "Choix du mode de paiement" },
  {  id: 4, title: "Statut du paiement"}
]

const cgpFinalisationSteps = [
  {  id: 0, title: "Modalités d'investissement" },
  {  id: 1, title: "Renseignement" },
  {  id: 2, title: "Choix du mode de paiement" },
  {  id: 3, title: "Signature des documents" },
]

const Finalise = ({cgpUser}) => {
  let { id, clientId } = useParams();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const cgp = window.location.href.includes("cgp");
  const storageDefaultProfile = useState(JSON.parse(localStorage.getItem('defaultProfile')))[0];
  const defaultProfile = cgp ? (state?.clientType !== 'Company') : storageDefaultProfile
  const [object, setObject] = useState({});
  const [company, setCompany] = useState(JSON.parse(localStorage.getItem('company')));
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [amount, setAmount] = useState(localStorage.getItem('InvestmentAmount') || 0);
  const transactionStatus = localStorage.getItem('transactionStatus');
  const navigate = useNavigate();
  const [signatureModal, setSignatureModal] = useState(false);
  const [reloadObject, setReloadObject] = useState(false);

  const fetchUser = async () => {
    if (cgp) {
      const response = await CgpNetwork.getClient(clientId, 'User');
      setObject({...response});
    } else {
      const response = await AuthenticationNetwork.loadUserData();
      response && setObject({...response});
    }
  }

  async function fetchCompany() {
    if (cgp) {
      const response = await CgpNetwork.getClient(clientId, 'Company');
      setObject({...response});
    } else {
      await CompanyNetwork.getCompany(company.id).then(response => {
        setObject({...response});
        setLoading(false);
      });
    }
  }

  const fetchOpportunity = async () => {
    const response = await ProductNework.getProduct(id, clientId, defaultProfile ? 'User' : 'Company');
    if (response) {
      setProduct(response);
      setLoading(false);
    }
  }

  const generateProductAnnexe = async () => {
    const response = await CgpNetwork.generateProductAnnexe(product?.id, product?.name);
    if (response?.status === 200) {
      fetchOpportunity();
    } else if (response?.status === 400) {
      toast.error("Une erreur est survenue lors de la génération du document")
    }
  };

  useEffect(() => {
    if (defaultProfile) {
      fetchUser().then(() => fetchOpportunity())
    } else {
      fetchCompany().then(() => fetchOpportunity())
    }
  }, [reloadObject]);

  useEffect(() => {
    if (activeStep !== 4 && !status) {
      localStorage.setItem('product', JSON.stringify(product));
      localStorage.setItem('productUrl', window.location.href);
      if (transactionStatus) {
        localStorage.removeItem('transactionStatus');
        window.location.replace(`/overview`);
      }
    }
  }, [product, activeStep]);

	useEffect(() => {
		if (defaultProfile) {
			activeStep === 0 && localStorage.removeItem('UserSurveyStep')
		} else {
			activeStep === 0 && localStorage.removeItem('CompanySurveyStep')
		}
	}, [activeStep]);

  useEffect(() => {
    if (cgp) {
      if (product?.cgp_already_signed_doc === null) {
        generateProductAnnexe();
      } else if (product?.cgp_already_signed_doc?.status === 'pending') {
        setSignatureModal(true)
      }
    }
  }, [product]);

  function calculateActiveStep() {
    let step;
    if (product.name !== 'Livret P.') {
      if (cgp) {
        if (object.survey_completed_at === null && searchParams.get('transaction_type') === null) {
          step = 1;
        } else if (object.survey_completed_at !== null && (searchParams.get('transaction_type') === null) && (searchParams.get('documents_signature') === null)) {
					product.subscription_state === 'waiting_list_with_info' ? step = 3 : step = 2
        } else if (searchParams.get('transaction_type')) {
          step = 3;
        } else if (searchParams.get('documents_signature') === 'sent') {
          step = 4;
        } else if (searchParams.get('status')) {
          step = 4;
        } else {
          step = 0;
        }
      } else {
        if ((defaultProfile ? (object.survey_completed_at === null) : (object.survey_completed_at === null) && searchParams.get('transaction_id') === null)) {
          step = 1;
        } else if ((defaultProfile ? (object.survey_completed_at !== null) : (object.survey_completed_at !== null)) && (searchParams.get('transaction_id') === null)) {
          step = 2;
        } else if (searchParams.get('transaction_id')) {
					product.subscription_state === 'waiting_list_with_info' ? step = 4 : step = 3;
        } else if (searchParams.get('status')) {
          step = 4;
        } else {
          step = 0;
        }
      }
    } else if (product.name === 'Livret P.') {
      if ((defaultProfile ? (object.kyc_verification_status !== "verified") : (object.kyc_status !== "verified")) && searchParams.get('transaction_id') === null) {
        step = 1;
      } else if ((defaultProfile ? (object.kyc_verification_status === "verified") : (object.kyc_status === "verified")) && searchParams.get('transaction_id') === null) {
        step = 2;
      } else if (searchParams.get('transaction_id')) {
        step = 3;
      } else if (searchParams.get('status')) {
        step = 4;
      } else {
        step = 0;
      }
    }
    return step;
  }

  useEffect(() => {
    const newActiveStep = calculateActiveStep();
    activeStep !== 0 && setActiveStep(newActiveStep);
    if (cgp) {
      searchParams.get('documents_signature') === 'sent' && setActiveStep(4)
    }
  }, [product, searchParams, object, activeStep]);
  const checkSuccess = state => {
    state && setActiveStep(activeStep + 1);
  }
  const rollbackStep = state => {
    state && setActiveStep(activeStep - 1);
  }
  const checkLink = () => {
    if (cgp) {
      localStorage.setItem('User', clientId)
      return state?.clientType === 'Company' ? navigate(`/cgp/clients/${clientId}/personne-morale/edition`) : navigate(`/cgp/clients/${clientId}/personne-physique/edition`)
    } else {
      return product.name === 'Livret P.' ? navigate(`/kyc`) : navigate(`${window.location.pathname}/user_survey${window.location.search}`)
    }
  }

  const updateObject = (arg) => {
    setReloadObject(arg);
  }

  const checkStep = () => {
    if (loading && !product){
      return (
        <div className="loader-display">
          <div className="desktop-display">
            <FinaliseLoader0 />
          </div>
          <div className="mobile-display">
            <FinaliseLoaderMobile />
          </div>
        </div>
      )
    } else if (!loading && product) {
      switch (activeStep) {
        case 0:
          return <StepsContainer
              children={<div>
                <h5 className="os-subtitle1">Informations</h5>
                <div className="product-information-text" style={{marginBottom: 16}}>
                  <li>L'investissement minimum pour ce produit est de {MoneyHelper.formatEuro(cgp ? product.cgp_minimum_investment : product.minimum_investment)}</li>
                  <div dangerouslySetInnerHTML={{__html: product.informations}} />
                </div>
                {(object.profile_score !== 0 && object.profile_score < 4) && (
                    <>
                      <Divider />
                      <p className={`warning os-body1`} style={{marginTop: 16}}>{cgp ? 'Le score du client' : 'Votre score'} {object?.profile_score} est inférieur au SRI du produit qui est de (4 / 7) {!cgp && ",nous vous conseillons de bien évaluer votre investissement"}</p>
                    </>
                )}
              </div>}
              children1={<DepositForm product={product} page="opportunityStep1" func={checkSuccess} minimumInvestment={cgp ? product.cgp_minimum_investment : product.minimum_investment} cgp={cgp} />}
              product={product} step={activeStep} minimumInvestment={cgp ? product.cgp_minimum_investment : product.minimum_investment} object={object}
          />;
        case 1:
          return <StepsContainer
            children={<EmptyContent illustration={<NoData />}
                                    text={cgp ? "Pour continuer l'investissement du client dans ce produit, nous avons besoin d'avoir quelques informations" : "Pour continuer votre investissement, nous avons besoin de faire connaissance"}
                                    cta={{title: cgp ? "Remplir les informations" : "Je réponds au questionnaire", onClick: () => checkLink(), variant: "primary-full", size: "large"}}
            />}
            product={product}
            step={activeStep}
            object={object}
          />;
        case 2:
          return cgp ? <CgpPaiementChoice object={object} product={product} clientId={clientId} clientType={state?.clientType} nextStep={checkSuccess} updateObject={updateObject}/>  : <Signature product={product} nextStep={checkSuccess} updateAmount={setAmount} storageDefaultProfile={defaultProfile} step={activeStep} cgp={cgp} clientId={clientId} clientType={!defaultProfile ? 'Company' : 'User'} minimumInvestment={cgp ? product.cgp_minimum_investment : product.minimum_investment}/>;
        case 3:
          return cgp ? <Signature product={product} nextStep={checkSuccess} updateAmount={setAmount} storageDefaultProfile={defaultProfile} step={activeStep} cgp={cgp} clientId={clientId} clientType={!defaultProfile ? 'Company' : 'User'} minimumInvestment={cgp ? product.cgp_minimum_investment : product.minimum_investment}/> : <Paiement product={product} nextStep={checkSuccess} object={object}/>
        case 4:
          return cgp ? <SentSuccess product={product} object={object} clientId={clientId} clientType={!defaultProfile ? 'Company' : 'User'} cgpUser={cgpUser}/> : <Paiement product={product} nextStep={checkSuccess} step={activeStep} rollback={rollbackStep} />
        default:
          return <div>Not Found</div>;
      }
    }
  }

  const checkFinalisationsSteps = () => {
    return cgp ? cgpFinalisationSteps : finalisationSteps;
  }

  const updateShow = (bool) => {
    setSignatureModal(bool)
  }

  const handleDirectStepChange = (step) => {
    step <= 2 ? setActiveStep(step) :
      toast.error("Vous devez valider les autres étapes pour pouvoir accéder aux suivantes", {
        toastId: "changeStepError"
      })
  };

  return (
    <div style={{marginTop: 32}}>
      <Stepper activeStep={activeStep} alternativeLabel className="stepper-kyc os-scroll-shadows">
        {checkFinalisationsSteps().map(step => (
          <Step key={step.title} onClick={() => (activeStep <= 2) && handleDirectStepChange(step.id)} style={activeStep <= 2 ? {cursor: "pointer"} : {}}>
            <StepLabel>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {checkStep()}
      {(signatureModal && cgp) && (
        <IntegratedSignature
          show={signatureModal}
          updateShow={updateShow}
          signatureLink={product?.cgp_already_signed_doc?.file}
          body={`Ici vous pourrez consulter et signer l'annexe du produit ${product?.name}, si celui-ci n'apparait pas veuillez nous contacter`}
          title={`Signature annexe produit - ${product?.name}`}
        />
      )}
    </div>
  );
};

export default Finalise;
